import changeForm from "../utils/changeForm";
import clickButton from "../utils/clickButton";
import createDepositHandler from "../utils/createDepositHandler";
import createPurchaseHandler from "../utils/createPurchaseHandler";
import formError from "../utils/formError";
import openPopup from "../utils/openPopup";
import paymentComplete from "../utils/paymentComplete";
import sendForm from "../utils/sendForm";
import submit from "../utils/submit";
import verification from "../utils/verification";

const useCashGA = () => {
	const { data: appInitData } = useAppInitData();

	const getEmail = () => appInitData.value?.email || "";
	const getPhone = () => appInitData.value?.phone || "";

	onMounted(() => {
		window.$store = window.$store || {};
		window.$store.gaCash = {
			deposit: createDepositHandler({ getEmail, getPhone }),
			paymentComplete,
			changeForm,
			clickButton,
			sendForm,
			purchase: createPurchaseHandler({ getEmail, getPhone }),
			verification,
			submit,
			openPopup,
			formError
		};
	});
};

export default useCashGA;
