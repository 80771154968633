const clickButton = ({
	event = "click_button",
	buttonName,
	location
}: {
	event: string;
	buttonName: string;
	location: string;
}) => {
	window.dataLayer.push({ event, button_name: buttonName, location });
};

export default clickButton;
