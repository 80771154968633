const openPopup = ({
	event = "open_popup",
	formName,
	message,
	location
}: {
	event: string;
	formName: string;
	message: string;
	location: string;
}) => {
	window.dataLayer.push({ event, form_name: formName, message, location });
};

export default openPopup;
