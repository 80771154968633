const verification = ({
	event = "verification",
	location,
	step,
	type
}: {
	event: string;
	location: string;
	step: string;
	type: string;
}) => {
	window.dataLayer.push({ event, step, location, type });
};

export default verification;
