const changeForm = ({
	event = "change_form",
	formName,
	formField
}: {
	event: string;
	formName: string;
	formField: string;
}) => {
	window.dataLayer.push({
		event,
		form_name: formName,
		form_field: formField
	});
};

export default changeForm;
