const submit = ({
	event = "verification",
	location,
	step,
	type
}: {
	event: string;
	location: string;
	step: string;
	type: string;
}) => {
	window.dataLayer.push({ event, location, step, type });
};

export default submit;
