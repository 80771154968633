<script setup lang="ts">
useCashGA();
useCash();

const {
	public: { cashDevMode }
} = useRuntimeConfig();
</script>

<template>
	<Teleport v-if="!cashDevMode" to="body">
		<div id="cash" />
	</Teleport>
</template>
