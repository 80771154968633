/* eslint-disable indent */
import sha256 from "./sha256";

const createPurchaseHandler =
	({ getEmail, getPhone }: { getEmail: () => string; getPhone: () => string }) =>
	async ({
		value,
		discount,
		coins,
		bonus,
		affiliation,
		playerId,
		quantity,
		type,
		offer
	}: {
		value: string;
		discount: string;
		coins: string;
		bonus: string;
		affiliation: string;
		playerId: string;
		quantity: number;
		type: string;
		offer: string;
	}) => {
		const email = getEmail();
		const phone = getPhone();
		window.dataLayer.push({
			event: "purchase",
			type,
			ecommerce: {
				value,
				discount,
				coins,
				bonus,
				affiliation,
				playerId,
				quantity,
				offer,
				...(quantity === 1
					? {
							currency: "USD",
							sha256_email_address: await sha256(email || ""),
							sha256_phone_number: await sha256(phone || "")
						}
					: {})
			}
		});
	};

export default createPurchaseHandler;
